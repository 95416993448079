html, body, #root {  
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
body {
  font-family: "Roboto", "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a, img {
  -webkit-tap-highlight-color: transparent;
}
.column {
  flex-direction: column;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.row {
  flex-direction: row;
}
