.app {  
  width: 100%;
  height: 100%;  
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;  
  width: 300px;
  height: 100%;
  margin: auto;
}
.container h1 {
  color: #FFF;
  letter-spacing: 2px;
  font-family: 'Quintessential', cursive;
  font-size: 2em;
  font-weight: 400;
  margin: 15px 0px 0px 0px;
}
.justin {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.about {
  margin: 0;
}
.about h2 {
  color: #999;
  font-size: 1.5em;
}
.links {
  margin-top: 30px;
}
.links img {
  width: 50px;
  margin: 0px 10px 0px 10px;
}
.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.project {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
}
.spotlight {
  background-image: radial-gradient(circle, #777, #555 60%);
}
.dropshadow {
  filter: drop-shadow(3px 3px 2px #333);
}
#tsparticles {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
}
